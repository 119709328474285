<script setup lang="ts">
import { useI18n } from 'vue-i18n';

// Composables
const router = useRouter();
const { t } = useI18n();
// Props
const props = defineProps<{
  title: string;
  backUrl?: string;
  formDirty?: boolean;
  breadcrumb?: {
    name: string;
    url: string;
  }[];
}>();

const navigate = (url?: string) => {
  if (props.formDirty) {
    appAlertStore().showAlert({
      type: 'warning',
      title: t('global.form_dirty'),
      message: t('global.form_dirty_message'),
      successText: null,
      button: t('global.form_dirty_confirm_text'),
      callback: () => {
        router.push(url);
      },
    });
  } else {
    router.push(url);
  }
};
</script>
<template>
  <div class="flex flex-col bg-white px-6 border-b border-gray-200">
    <div class="flex justify-between items-center pb-4 pt-4">
      <div class="flex items-center justify-start">
        <p
          v-if="backUrl || breadcrumb"
          class="p-1 hover:bg-gray-200 cursor-pointer rounded mr-4"
          @click="navigate(backUrl || breadcrumb.at(-1).url)"
        >
          <ui-icon name="ArrowLeft" class="w-4 h-4 text-gray-600" />
        </p>
        <div v-for="el in breadcrumb" :key="el.name" class="flex flex-row items-center">
          <p class="font-regular text-base text-gray-700 mr-4 hover:underline cursor-pointer" @click="navigate(el.url)">
            {{ el.name }}
          </p>
          <ui-icon name="ChevronRight" class="w-4 h-4 text-gray-600 mr-4" />
        </div>
        <h2 class="font-semibold text-base text-gray-700 mr-4">
          {{ title }}
        </h2>
        <slot name="right-title" />
      </div>

      <div class="flex gap-2 min-h-[32px]">
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>
